<template>
  <div class="kt-page--fluid kt-subheader--transparent full-page">
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
          <div class="kt-header__brand-logo my-5">
            <div class="kt-container">
              <router-link :to="{ name: 'base'}"><h2 class="text-primary">Size Chart ‑ Clothes Fit Guide</h2></router-link>
            </div>

          </div>
          <div class="kt-container kt-body kt-grid kt-grid--ver page-content">
            <div class="card card-custom ">
              <div class="card-body">
                <h5>Why I should install this app?</h5>
                <p>This app allows your shoppers to purchase their selected item without going through the cart page.
                  Studies have shown that most abandoned carts are due to this small time for customers to reconsider or
                  find an alternative to your store.
                </p>
                <h5>What should I do after install?</h5>
                <p>Nothing! It’s a simple install and ready to use app! Optionally, you can change some settings in
                  Shopify admin if you need to.</p>

                <h5>I’ve made some changes in the settings, but it hasn't been applied yet, why?</h5>
                <p>It takes typically 5 minutes or more for your changes to take effect. If things still doesn’t change
                  double check that you have properly made your changes, and wait another 5 minutes. If the problem
                  persists try contacting our support support@sweetecom.com.
                </p>
                <h5>How could I change some styles or widget position?</h5>
                <p>Try Placing: <code>{{ renderBlock }}</code> to your product template
                  (sections/product-template.liquid) and you could change CSS-styles as you wish in your theme styles.
                  If you have any trouble you can either contact our support support@sweetecom.com
                </p>
                <h5>What should I do after uninstall app?</h5>
                <p>Usually, nothing, but, if you've placed the <code>{{ renderBlock }}</code> to your
                  product-template.liquid, you should also delete it. Also, we will automatically delete all data about
                  your previous installation.
                </p>
                <h5>What do I do if I encounter problems or bugs?</h5>
                <p>There shouldn’t be any, but if the app has issues try identify the problem or bug and notify our
                  support team support@sweetecom.com.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent'

export default {
  name: "Faq",
  components: {
    FooterComponent
  },
  computed: {
    renderBlock() {
      return '<div id="fastcheckout_sweetecom"></div>';
    }
  }
}
</script>

<style lang="scss">
</style>